body {
  color: $body-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
}

// Headings
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 $headings-margin-bottom;

  color: $headings-color;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

.h1,
h1 {
  font-size: $font-size-h1;
}

.h2,
h2 {
  font-size: $font-size-h2;
}

.h3,
h3 {
  font-size: $font-size-h3;
}

.h4,
h4 {
  font-size: $font-size-h4;
}

.h5,
h5 {
  font-size: $font-size-h5;
}

.h6,
h6 {
  font-size: $font-size-h6;
}

// Texts
p {
  margin-top: 0;
  margin-bottom: $paragraph-margin;

  &.lead {
    margin-bottom: $paragraph-lead-margin-bottom;

    font-size: $paragraph-lead-font-size;
    line-height: 1.8;
  }

  &.small {
    font-size: $font-size-sm;
  }
}

.text-small {
  font-size: $font-size-sm;
}

strong,
b {
  font-weight: $font-weight-bold;
}

p a,
.link {
  color: $link-color;
  text-decoration: $link-decoration;

  @include transition(color);

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// Lists
ul,
ol {
  margin-top: 0;
  margin-bottom: $list-margin;

  padding-left: 0;

  list-style: none;

  li {
    position: relative;

    margin-bottom: $list-item-margin;
    padding-left: 20px;

    ul,
    ol {
      margin-top: $list-item-margin;
      margin-bottom: 0;
    }

    &::before {
      display: block;
      position: absolute;
      left: 0;
    }
  }
}

ul {
  li {
    &::before {
      content: '';

      top: 8px;
      left: 0;

      width: 8px;
      height: 8px;

      border: 1px solid transparent;
      border-radius: 50%;

      background-color: color(primary);
    }

    ul li::before {
      border-color: color(primary);

      background: transparent;
    }
  }
}

ol {
  counter-reset: counter;

  > li {
    counter-increment: counter;

    &::before {
      content: counter(counter);

      top: 0;

      color: color(primary);
      font-weight: $font-weight-bold;
    }
  }
}

hr {
  width: 100%;
  height: 1px;
  margin: px(3) 0;
  border: none;
  background-color: color(gray, 400);
}

// Blockquote
blockquote {
  quotes: '“' '”' '‘' '’';
  position: relative;

  margin: $default-margin * 2 0;
  padding: $default-padding;
  border-left: 10px solid color(primary);
  border-radius: $border-radius;
  background: color(gray, 100);

  cite {
    font-weight: $font-weight-bold;

    &::before {
      content: '\2014';
      margin-right: px(1.5);
    }
  }
}

// Definition List
dl {
  dt {
    font-weight: $font-weight-bold;

    dfn {
      font-style: normal;
    }
  }

  dd {
    margin-bottom: $default-margin;
    margin-left: 0;
  }
}

// Helpers
.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-regular {
  font-weight: $font-weight-normal;
}

.font-italic {
  font-style: italic;
}

.font-caps {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}

.prewrap {
  white-space: pre-wrap;
}

.break-word {
  word-wrap: break-word;
}

.underline {
  text-decoration: underline;
}

.truncate {
  max-width: 100%;
  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;
}

.error {
  color: color(danger);
}

.warning {
  padding: px(1);
  background-color: color(warning);
}
