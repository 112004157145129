@import '@socialbrothers/assets/styles/abstracts';

.Sidebar {
  display: flex;
  flex-direction: column;
  width: 225px;
  min-width: 225px;
  min-height: 100vh;
  overflow-y: scroll;

  background-color: color(primary);

  ul {
    margin: 0;

    li {
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  .Title {
    display: block;
    max-width: 100%;
    margin: $sidebar-spacing 0 px(0.5) 0;
    padding: 0 $sidebar-spacing;
    overflow: hidden;

    color: rgba(color(white), 0.8);

    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    letter-spacing: 0.8px;

    text-overflow: ellipsis;
    text-transform: uppercase;

    white-space: nowrap;

    &::before {
      display: none;
    }
  }

  .Item {
    display: flex;
    position: relative;
    padding: px(1.5) $sidebar-spacing;
    opacity: 0.8;
    color: color(white);
    cursor: pointer;

    @include transition(all);

    .Icon {
      width: 20px;
      margin-top: 2px;
      margin-right: px(1);
      vertical-align: middle;
    }

    &.ItemDropdown {
      &::after {
        content: '\f054';

        position: absolute;
        top: 15px;
        right: $sidebar-spacing;
        font-family: $font-family-icons;
        font-size: $font-size-xxs;

        @include transition(transform);
      }
    }

    &.ItemActive {
      opacity: 1;
      background-color: color(primary, dark);
      font-weight: $font-weight-bold;

      &::after {
        transform: rotate(90deg);
      }

      &.ItemDropdown {
        font-weight: $font-weight-normal;
      }
    }

    &:hover {
      opacity: 1;
      background-color: color(primary, dark);
    }
  }

  .Subitem {
    display: flex;
    position: relative;
    padding: px(0.25) $sidebar-spacing;
    opacity: 0.8;
    color: color(white);
    cursor: pointer;

    &:hover,
    &.SubitemActive {
      text-decoration: underline;
    }
  }

  .Dropdown {
    display: none;
    padding-bottom: px(1);
    background-color: color(primary, dark);

    &.DropdownActive {
      display: block;
    }
  }

  .Footer {
    position: relative;
    margin-top: auto;
    padding: px(1) 0 px(2) 0;
    text-align: center;
    font-size: $font-size-xs;
    color: color(white);

    .Spinner {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      padding: px(1) px(1) px(2) px(1);
      background-color: color(primary);
    }
  }
}

.Brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  min-height: 70px;
  background-color: color(primary, dark);
  color: color(white);
  font-size: $font-size-lg;
  font-weight: 800;
  letter-spacing: 0.8px;

  &:hover {
    color: color(white);
  }
}

@include media-breakpoint-down(md) {
  .Overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(color(black), 0.6);
    cursor: pointer;
  }

  .Sidebar {
    position: fixed;
    z-index: 1001;
    top: 0;
    bottom: 0;
  }
}
