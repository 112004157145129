@import '@socialbrothers/assets/styles/styles.scss';

.Wrapper {
  width: 100%;
  overflow-x: scroll;
  overflow-y: auto;

  table {
    width: 100%;
  
    tbody {
      tr {
        border-bottom: 1px solid color(gray, 300);
        
        td, th {
          white-space: nowrap;
          text-align: left;
          padding-top: px(2);
          padding-bottom: px(2);
          
          > div {
            text-align: left;
          }
        }

        th {
          width: 1px;
          padding-right: px(6);
        }

        &:first-child {
          td, th {
            padding-top: 0;
          }
        }

        &:last-child {
          border-bottom: 0;
          
          td, th {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}