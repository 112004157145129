@import '@socialbrothers/assets/styles/abstracts';

.BaseForm {
	&__Error {
		display: block;
		background-color: color(danger);
		color: color(white);
		padding: px(1) px(2);
		border-radius: $border-radius;
		margin-bottom: px(3);
		font-size: $font-size-sm;

		i {
			margin-right: px(1);
		}
	}
}
