@import '@socialbrothers/assets/styles/abstracts';

.PageUnauthenticated {
	margin-left: $default-margin;
	margin-right: $default-margin;

	.Image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 390px;
		max-height: 50vh;
		object-fit: cover;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		&::after {
			content: "";
			position: absolute;
			inset: 0;
			background: linear-gradient(45deg, rgba(color(primary, dark), .95) 0%, rgba(color(primary, light), .5) 100%);
		}
	}

	.Card {
		max-width: 540px;
		width: 100%;
		position: relative;
		margin: 0 auto;
		margin-top: 195px;
		border-radius: $border-radius;
		padding: px(5);
		background: $body-background;
		box-shadow: 16px 32px 40px 0 rgba(0,0,0,0.16);
		border-bottom: 16px solid color(primary);

		.Logo {
			display: block;
			max-height: 85px;
			object-fit: contain;
			margin: 0 auto px(7) auto;
		}

		.Heading {
			margin-bottom: px(4);

			> *:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.Card {
			margin-top: px(6);
			padding: px(3);

			.Logo {
				margin-bottom: px(5);
			}
		}
	}
}
